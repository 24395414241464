import * as React from "react";
import { Grid, Card, CardContent, Button, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Title, useNotify } from 'react-admin';
import { useParams } from 'react-router-dom';

const { REACT_APP_API_URL } = process.env;

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export const EditSalesDetails = () => {
    const [data, setData] = React.useState({});
    const notify = useNotify();
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [openEmailModal, setOpenEmailModal] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [isRevalidatePopUpOpen, setisRevalidatePopUpOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [invalid, setInvalid] = React.useState(false);
    const [loading, setloading] = React.useState(false);
    const [onboarded, setOnboarded] = React.useState(false);
    const { id } = useParams();

    React.useEffect(() => {
        getSalesInfo()
    }, []);

    const getSalesInfo = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            const request = new Request(`${REACT_APP_API_URL}/customercare/sales/details`, {
                method: 'POST',
                body: JSON.stringify({ id: id }),
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            let resp = await fetch(request);
            let body = await resp.json()
            setInvalid(body.setAlreadyInvalidated);
            setData(body.salesData);
            setOnboarded(body.onboarded);
            setloading(true);
        } catch (err) {
            setloading(false)
            notify(`${err.message}`, { type: 'error' });
        }
    }

    const handleOpenEmailModal = () => {
        setOpenEmailModal(true);
    };

    const handleCloseEmailModal = () => {
        setOpenEmailModal(false);
        setEmail("")
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openRevalidatePopUp = () => {
        setisRevalidatePopUpOpen(true)
    }

    const closeRevalidatePopUp = () => {
        setisRevalidatePopUpOpen(false)
    }

    const AddToIgnoreUsers = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            var invalidateObj = {}
            invalidateObj.transactionEmailId = data.transactionEmailId
            invalidateObj.emailId = data.emailId
            invalidateObj.name = data.name
            invalidateObj.transactionName = data.transactionName
            invalidateObj.itemId = data.itemId
            const request = new Request(`${REACT_APP_API_URL}/customercare/sales/invalidateCustomer`, {
                method: 'POST',
                body: JSON.stringify(invalidateObj),
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            let resp = await fetch(request)
            let body = await resp.json()
            if (body.result == true) {
                setInvalid(true)
                getSalesInfo()
                notify(`${body.value}`, { type: 'success' });
                handleClose()
            } else {
                notify(`${body.value}`, { type: 'warning' });
                handleClose()
            }
        }
        catch (e) {
            notify(`${e.message}`, { type: 'error' });
            handleClose()
        }
    }

    const RemoveFromIgnoreUsers = async () => {
        try {
            let token = localStorage.getItem('employee_token')
            var invalidateObj = {}
            invalidateObj.transactionEmailId = data.transactionEmailId
            invalidateObj.emailId = data.emailId
            invalidateObj.itemId = data.itemId
            const request = new Request(`${REACT_APP_API_URL}/customercare/sales/revalidateCustomer`, {
                method: 'POST',
                body: JSON.stringify(invalidateObj),
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            let resp = await fetch(request)
            let body = await resp.json()
            if (body.result == true) {
                getSalesInfo()
                setInvalid(false)
                closeRevalidatePopUp()
                notify(`${body.value}`, { type: 'success' });
                handleClose()
            } else {
                notify(`${body.value}`, { type: 'warning' });
                handleClose()
            }
        }
        catch (e) {
            notify(`${e.message}`, { type: 'error' });
            handleClose()
        }
    }

    const ignoreUsersBody = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.title}>
                <h2 id="simple-modal-title">Alert</h2>
                <p id="simple-modal-description">
                    Are you sure, that you want to invalidate the user?
                </p>
            </div>
            <div className={classes.modalButton}>
                <Button size="large" variant="outlined" onClick={() => AddToIgnoreUsers()}>Invalidate User</Button>
            </div>
        </div>
    );

    const revalidateUsersBody = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.title}>
                <h2 id="simple-modal-title">Alert</h2>
                <p id="simple-modal-description">
                    Are you sure, that you want to re-validate the user?
                </p>
            </div>
            <div className={classes.modalButton}>
                <Button size="large" variant="outlined" onClick={() => RemoveFromIgnoreUsers()}>Re-validate User</Button>
            </div>

        </div>
    );

    const changeTransactionEmailId = async () => {
        try {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(email)) {
                let token = localStorage.getItem('employee_token')
                var transactionProfileData = {}
                transactionProfileData.itemId = data.itemId
                transactionProfileData.transactionEmailId = data.transactionEmailId
                transactionProfileData.emailId = email
                transactionProfileData.modelName = getSaunaModalImageFromSaunaId(data.itemId)
                transactionProfileData.transactionId = data.transactionId
                const request = new Request(`${REACT_APP_API_URL}/customercare/sales/changeEmail`, {
                    method: 'POST',
                    body: JSON.stringify({ data: transactionProfileData }),
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
                });
                let resp = await fetch(request)
                let body = await resp.json()
                if (body.result == true) {
                    handleCloseEmailModal()
                    getSalesInfo()
                    notify(`${body.message}`, { type: 'success' });
                } else {
                    if(body?.message?.toLowerCase() === 'no data found for the provided transaction details'){
                        notify('This user is not onboarded. Once the user is onboarded, you can change the email ID', { type: 'warning' });
                    } else {
                        notify(`${body.message}`, { type: 'warning' });
                    }
                }
            } else {
                // invalid email, maybe show an error to the user.
                notify(`invalid email format`, { type: 'warning' });
            }
        }
        catch (e) {
            notify(`${e.message}`, { type: 'error' });
        }
    }

    const getSaunaModalImageFromSaunaId = (ID) => {
        let modalNumber = ID[1]
        switch (parseInt(modalNumber)) {
            case 1:
                return "aSPIRE"
            case 2:
                return "bELIEVE"
            case 3:
                return "cONQUER"
            case 4:
                return "dISCOVER"
            case 5:
                return "eMPOWER"
            case 6:
                return "eMPOWER"
            default:
                return "bELIEVE"
        }
    }

    const changeEmailModalBody = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.title}>
                <h2 id="simple-modal-title">Alert</h2>
                <p id="simple-modal-description">
                    Enter the new email address for {data.transactionEmailId}
                </p>
                <input className={classes.input} type="email" name="email" placeholder="email" value={email} onChange={e => setEmail(e.target.value)} required />
            </div>
            <div className={classes.modalButton}>
                <Button size="large" variant="outlined" onClick={() => changeTransactionEmailId()}>Change emailId</Button>
            </div>
        </div>
    );

    return (
        <div className={classes.grid}>
            {data.result !== false && loading &&
                <Grid container spacing={2} >
                    <Grid item lg={9} md={9} sm={9} xs={12}>
                        <Card variant="outlined" sx={styles.card}>
                            <Title className={classes.title} title="Customer Sales Details" />
                            {data ? (
                                <CardContent>
                                    {Object.keys(data).map((item, index) => {
                                        if (data[item]) {
                                            if (!Array.isArray(data[item])) {
                                                if (typeof data[item] !== 'object' && data[item] !== null) {
                                                    return (
                                                        <div className={classes.row}>
                                                            <span className={classes.listHeader}>{Object.keys(data)[index]}:</span>
                                                            <span className={classes.listValue}>{data[item]}</span>
                                                        </div>
                                                    )
                                                }
                                                else if (typeof data[item] === 'object' && data[item] !== null) {
                                                    let reactChildElements = [];
                                                    Object.keys(data[item]).map((objKey, i) => {
                                                        reactChildElements.push(
                                                            <div className={classes.row}>
                                                                <span className={classes.listHeader}>{objKey}:</span>
                                                                <span className={classes.listValue}>{data[item][objKey]}</span>
                                                            </div>
                                                        )
                                                    })
                                                    return reactChildElements
                                                }
                                            } else {
                                                //if array ctrl comes here
                                                return null
                                            }
                                        } else {
                                            return null;
                                        }
                                    })}
                                </CardContent>
                            ) : null}
                            <div className={classes.btnWrapper}>
                                <div className={classes.restBtn}>
                                    {!invalid ?
                                        (<Button size="large" variant="outlined" onClick={() => handleOpenEmailModal()}>change EmailId</Button>) : null}
                                </div>
                                {!onboarded ? (
                                    <div className={classes.restBtn}>
                                        {!invalid ?
                                            (<Button size="large" variant="outlined" onClick={() => handleOpen()}>Invalidate User</Button>) :
                                            (<Button size="large" variant="outlined" onClick={() => openRevalidatePopUp()}>revalidate User</Button>)}
                                    </div>
                                ) : null}
                            </div>
                            <Modal open={openEmailModal} onClose={handleCloseEmailModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
                                {changeEmailModalBody}
                            </Modal>
                            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
                                {ignoreUsersBody}
                            </Modal>
                            <Modal open={isRevalidatePopUpOpen} onClose={closeRevalidatePopUp} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
                                {revalidateUsersBody}
                            </Modal>
                        </Card>
                    </Grid>
                </Grid>}
        </div>
    )
};

const styles = {
    card: {
        border: '2px solid lightgrey',
        borderRadius: '5px',
        overflow: 'auto'
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "40%",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid lightgrey',
        boxShadow: theme.shadows[5],
        padding: '0px',
        "@media (max-width: 1280px)": {
            width: "50%"
        },
        "@media (max-width: 912px)": {
            width: "70%"
        },
        "@media (max-width: 650px)": {
            width: "90%"
        }
    },
    title: {
        margin: '15px'
    },
    modalButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: '15px',
        '& button': {
            width: 'auto',
            textAlign: "center",
            color: "white",
            border: "none",
            backgroundColor: "#f44336",
            '&:hover': {
                backgroundColor: "#ed574c"
            }
        }
    },
    input: {
        width: '100%',
        height: '35px',
        borderRadius: '5px',
        border: '1px solid darkgrey',
        textIndent: '10px',
        padding: '0px'
    },
    row: {
        display: 'flex',
        padding: '10px',
        flexDirection: 'row',
        alignItems: 'center',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    grid: {
        marginTop: '0px',
        "@media (max-width: 600px)": {
            marginTop: '25px',
            marginBottom: '10px',
            marginLeft: '5px'
        }
    },
    listHeader: {
        flex: 1,
        color: 'gray',
        fontSize: '14px',
        fontWeight: '400',
        textTransform: 'capitalize',
        "@media (max-width: 600px)": {
            fontSize: '18px',
            margin: '7px'
        },
    },
    listValue: {
        flex: 1.5,
        fontSize: '16px',
        color: 'currentcolor',
        fontWeight: '500',
        "@media (max-width: 600px)": {
            fontSize: '20px',
            margin: '7px',
            fontWeight: 'bold'
        }
    },
    restBtn: {
        textAlign: 'right'
    },
    btnWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '15px',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        },
        '& button': {
            margin: '10px 10px 10px 10px',
            width: 'auto',
            textAlign: "center",
            color: "white",
            border: "none",
            backgroundColor: "#f44336",
            '&:hover': {
                backgroundColor: "#ed574c"
            }
        }
    }
}));
